import React, { useState } from "react"

const Teams = ({ team }) => {
  const [clicked, setClicked] = useState(false);

  function openDetails(index) {
    setClicked(clicked === index ? -1 : index);
  }
  return (
    <section className="content">
   <div className="eighty-spacer"></div>
    <div className="content-block clearfix">
    {team.length
      ? team.map(cat => (
        <div>
        <div className="centered-intro-title-holder">
           <h1>
              {cat.name}</h1>
        </div>
        <div className="eighty-spacer"></div>

        <ul className="gridder clearfix">
        {cat.people.nodes.map((download, index) =>(
          <>
<li   className={`${clicked === index ? 'gridder-list selectedItem' : 'gridder-list'}`} data-griddercontent="#astrid-grey">
<div className="people-profile-block" onClick={() => openDetails(index)} onKeyDown={() => openDetails(index)} role="button" tabIndex={0}>
   <div className="profile-image-container">
      <div className="profile-image-holder">
         <div className="profile-image-holder">

            {download?.featuredImage?.mediaDetails?.file && 
               <img className="color-img" src={`/htbcontent/uploads/${download.featuredImage.mediaDetails.file}`} alt={download.title} title={download.title}/>
            }
            {download?.peopleDetails?.peopleBwImage?.mediaDetails?.file && 
               <img className="bw-img" src={`/htbcontent/uploads/${download.peopleDetails.peopleBwImage.mediaDetails.file}`} alt=""/>
            }
         </div>
      </div>
   </div>
   <div className="profile-title-container">
      <div className="profile-title-holder">
         <h3>{download.title}</h3>
         <span>{download.peopleDetails.personJobTitle}</span>
      </div>
      <div className="person-read-more">
         Read More
      </div>
      <div className="person-read-less">
         Read Less
      </div>
   </div>
</div>
</li>
{clicked === index ?
<div className="gridder-show">
<div className="gridder-padding">
<div  className="gridder-expanded-content"  dangerouslySetInnerHTML={{ __html: download.content }}/>
</div>
</div>
:
null }
</>
        ))}
        </ul>
        {clicked ?
          <>
        {cat.people.nodes.map((person,index) =>(
          <>
          </>
          ))}
          </>
          :
          null }
        </div>

      ))
    : null}
    </div>
</section>

  )
}

export default Teams
